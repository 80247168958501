@charset "utf-8";

// Light scheme
$background-color: hsl(40, 100%, 99%);
$text-color: hsl(0, 0%, 20%);
$text-color-subdued: hsl(0, 0%, 40%);
$accent-color: hsl(9, 56%, 50%);
$accent-inverted: hsl(0, 0%, 100%);
$accent-inverted-active: hsl(0, 4%, 14%);

// Dark scheme
$background-color-dark: hsl(0, 0%, 20%);
$text-color-dark: hsl(0, 0%, 100%);
$text-color-subdued-dark: hsl(0, 0%, 67%);
$accent-color-dark: hsl(16, 100%, 78%);
$accent-inverted-dark: hsl(0, 0%, 20%);
$accent-inverted-active-dark: hsl(0, 0%, 40%);

// Import partials.
@import "kids/base", "kids/images", "kids/index", "kids/layout", "kids/links",
  "kids/related_pages", "kids/typography";
