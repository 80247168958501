@import "../node_modules/highlight.js/scss/base16/solarized-dark";
@media (prefers-color-scheme: dark) {
  @import "../node_modules/highlight.js/scss/base16/solarized-light";
}

pre {
  background-color: #002b36;
  @media (prefers-color-scheme: dark) {
    background-color: #fdf6e3;
  }

  padding: 0.5rem 1rem;
  border-radius: 8pt;
}
